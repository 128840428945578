import React from "react"
import PropTypes from "prop-types"

import styles from "./layout.module.css"

const Layout = ({ children, outerContainer, innerContainer, reference }) => {
  return (
    <div
    id={ reference ? reference : null }
    style={{
      ...outerContainer
    }} >
      <div
      style={{
        ...innerContainer
      }}
      className={ styles.Wrapper }
      >
        {children}
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
